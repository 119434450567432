<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" style="min-width: 100px">
            <SelectMaterialWarehouse :label="$t('labels.warehouse_1')" :placeholder="$t('labels.warehouse_1')" name="id_material_warehouse" sort-name="material_warehouse_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.session_code')" :placeholder="$t('labels.session_code')" name="tracking_id" sort-name="tracking_id" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <DateRangeFilter :label="$t('labels.create_time')" :placeholder="$t('labels.create_time')" name="created_at" sort-name="created_at" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.supplier')" :placeholder="$t('labels.supplier')" name="supplier_name" sort-name="supplier_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <SelectFilter :options="imageOptions" :label="$t('labels.image')" :placeholder="$t('labels.image')" name="image" sort-name="image" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.name')" :placeholder="$t('labels.name')" name="name" sort-name="name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.goods_description')" :placeholder="$t('labels.goods_description')" name="description" sort-name="description" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <SelectFilter :options="statusOptions" :label="$t('labels.status')" :placeholder="$t('labels.status')" name="status" sort-name="status" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter class="input-highlight" :label="$t('labels.barcode')" :placeholder="$t('labels.barcode')" name="code" sort-name="code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter class="input-highlight" :label="$t('labels.sku')" :placeholder="$t('labels.sku')" name="sku" sort-name="sku" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.size')" :placeholder="$t('labels.size')" name="size" sort-name="size" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.quantitative')" :placeholder="$t('labels.quantitative')" name="quantitative" sort-name="quantitative" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 80px">
            <InputFilterFromTo :label="$t('labels.request_quantity')" :placeholder="$t('labels.request_quantity')" name="request_quantity" sort-name="request_quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 80px">
            <InputFilterFromTo :label="$t('labels.receipt_quantity')" :placeholder="$t('labels.receipt_quantity')" name="receipted_quantity" sort-name="receipted_quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 80px">
            <InputFilterFromTo :label="$t('labels.sub_quantity')" :placeholder="$t('labels.sub_quantity')" name="sub_quantity" sort-name="sub_quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.note')" :placeholder="$t('labels.note')" name="note" sort-name="note" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.employee_last_receipt')" :placeholder="$t('labels.employee_last_receipt')" name="employee_last_receipt" sort-name="employee_last_receipt" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" style="min-width: 100px">
            <DateRangeFilter :label="$t('labels.time')" :placeholder="$t('labels.time')" name="last_handle_time" sort-name="last_handle_time" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id" class="text-center">
          <td>
            <div style="font-size: 18px;">{{ item.material_warehouse_code }}</div>
          </td>
          <td>
            <div style="font-size: 18px;">{{ item.tracking_id }}</div>
          </td>
          <td>{{ formatDateTime(item.created_at) }}</td>
          <td>{{ item.supplier_name }}</td>
          <td><ImageViewer v-if="item.image" :url="item.image"/></td>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td style="min-width: 100px">
            <v-btn color="primary" x-small class="mr-1" v-if="!item.status" @click="showCheckDialog(item)" :disabled="isDisabledBtnCheck(item)">{{ $t('labels.check') }}</v-btn>
            <v-btn color="warning" x-small v-if="!item.status" @click="updateStatus(item, 'confirm')" :disabled="isDisabledBtnStatus(item)">{{ $t('labels.done_1') }}</v-btn>
            <v-btn color="secondary" x-small v-if="item.status" @click="updateStatus(item, 'reopen')" :disabled="isDisabledBtnStatus(item)" style="width: 86px">{{ $t('labels.reopen') }}</v-btn>
          </td>
          <td>
            <span style="font-size: 16px; font-weight: 600">{{ item.code }}</span>
          </td>
          <td>
            <span style="font-size: 16px; font-weight: 600">{{ item.sku }}</span>
          </td>
          <td>{{ item.size }}</td>
          <td>{{ item.quantitative }}</td>
          <td>
            <span class="text-h5 success--text">{{ item.request_quantity }}</span>
          </td>
          <td>
            <span class="text-h5 info--text">{{ item.receipted_quantity || 0 }}</span>
          </td>
          <td>
            <span class="text-h5 error--text">{{ item.sub_quantity }}</span>
          </td>
          <td>{{ item.note }}</td>
          <td>{{ item.employee_name }}</td>
          <td>{{ item.last_handle_time ? formatDateTime(item.last_handle_time) : '' }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <div>
            <div class="table-footer-count mr-2">{{ $t('labels.sku') }}: <b>{{ formatNumber(sum.sumChecked) }}/{{ formatNumber(sum.sumMaterial) }}</b></div>
            <div class="d-flex table-footer-count mt-1">
              <div class="mr-2">{{ $t('labels.request_quantity') }}: <b>{{ formatNumber(sum.sumRequestQuantity) }}</b></div>
              <div class="mr-2">{{ $t('labels.receipt_quantity') }}: <b>{{ formatNumber(sum.sumReceiptedQuantity) }}</b></div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="3"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex align-center justify-end">
          <div style="width: 100px;">
            <v-text-field class="c-input-xs" dense outlined hide-details v-model.number="page" :label="$t('labels.page')" :placeholder="$t('labels.page')"></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="checkDialog" persistent max-width="1366px">
      <CheckMaterial v-if="checkDialog && updatingItem.classify == 1" :item="updatingItem" @cancel="hideCheckDialog" @refreshData="getList"/>
      <CheckAccessory v-if="checkDialog && updatingItem.classify == 2" :item="updatingItem" @cancel="hideCheckDialog" @refreshData="getList"/>
    </v-dialog>

  </div>
</template>

<script>
import waitingMixins from "@/components/material/mixins/waitingMixins";

export default {
  components: {
    SelectMaterialWarehouse: () => import('@/components/table/SelectMaterialWarehouse'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    ImageViewer: () => import('@/components/common/ImageViewer'),
    CheckAccessory: () => import('@/components/material/CheckAccessory'),
    CheckMaterial: () => import('@/components/material/CheckMaterial'),
  },
  name: "WaitingDesktop",
  mixins: [waitingMixins],
}
</script>

<style scoped>

</style>
